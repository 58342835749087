import { AuthenticationError } from '@/utils/Authentication/AuthenticationError';
import { ApiError } from '@/utils/api';
import { ApiErrorResponse } from './types';

export async function processResponse<T>(response: Response) {
  validateAuthResponse(response);

  const contentType = response.headers.get('Content-Type') || '';
  if (contentType.split(';')[0] === 'application/json') {
    if (response.ok) {
      return (await response.json()) as T;
    }

    throw new ApiError((await response.json()) as ApiErrorResponse);
  }

  throw new Error(`Non JSON response ${response.headers.get('Content-Type')}`);
}

export async function processHtmlResponse(response: Response) {
  validateAuthResponse(response);

  const contentType = response.headers.get('Content-Type') || '';
  if (contentType.split(';')[0] === 'text/html') {
    if (response.ok) {
      return await response.text();
    }
    throw new Error(await response.text());
  }

  throw new Error(`Non TEXT/HTML response ${contentType}`);
}

export function validateAuthResponse(response: Response) {
  if ([403, 401].includes(response.status)) {
    const error: LoginError = {
      developer_message: `${response.status} -- ${response.url}`,
      error: `${response.status} -- ${response.url}`,
      status: response.status,
      authenticated: false,
    };

    throw new AuthenticationError(error);
  }
}

/**
 * Error types thrown by `processResponse`
 */
export type ProcessResponseError = AuthenticationError | ApiError | Error;

export function isProcessResponseError(error: unknown): error is ProcessResponseError {
  if (error instanceof AuthenticationError || error instanceof ApiError || error instanceof Error) {
    return true;
  }
  return false;
}
